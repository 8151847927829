import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ApiCall, apiClient, apiUrl } from '../api';
import BackButton from '../components/BackButton';
import SelectInput from '../components/forms/SelectInput';
import TextAreaInput from '../components/forms/TextAreaInput';
import TextInput from '../components/forms/TextInput';
import { useAuth } from '../context/AuthContext';
import useFetch from '../hooks/useFetch';
// Modal files include
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Link, useNavigate } from 'react-router-dom';

const LeaveApplication = () => {
  const { currentUser, tokenValue } = useAuth();
  const [message, setMessage] = useState('');
  const [leaveSummery, setLeaveSummary] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  // modal configuration data
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);

    if (message.toLocaleLowerCase() === 'leave added successfully')
      navigate('/dashboard');
  };

  const {
    data: employeeData,
    loading,
    error,
  } = useFetch(`/emp/${currentUser?._id}`);

  useEffect(() => {
    apiClient
      .get(
        `/leave/individualSummary/${
          currentUser?._id
        }/${new Date().getFullYear()}`,
        {
          headers: {
            authorization: `Bearer ${tokenValue}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setLeaveSummary(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentUser, tokenValue]);

  const remainingCasualLeave = employeeData?.casualLeave - leaveSummery?.casual;
  const remainingSickLeave = employeeData?.sickLeave - leaveSummery?.sick;
  const remainingFamilyLeave = employeeData?.familyLeave - leaveSummery?.family;
  const remainingHolidayLeave = employeeData?.holidayLeave - leaveSummery?.holiday;
  const remainingParenthoodLeave = employeeData?.parenthoodLeave - leaveSummery?.parenthood;
  const remainingMaritalLeave = employeeData?.maritalLeave - leaveSummery?.marital;
  const remainingBonusLeave = employeeData?.bonusLeave - leaveSummery?.bonus;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm();

  // Total Leaves Applied for
  const totalNumberOfLeaves = async (dayCount, startDate) => {
    const url = 'holiday';
    const yearlyHoliday = (await ApiCall(url, 'get', tokenValue))?.data?.data; //get all yearly holiday
    const yearlyHolidayDate = yearlyHoliday.map((result) => {
      const [day, month] = result.holidayDate.split('/');
      const year = new Date().getFullYear(); // Get current year
      return new Date(
        `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      ).getTime(); //Get date in millisecond
    });
    // [Fri, Sat]
    const weekHoliday = [5, 6];
    let numberOfHoliday = 0;

    for (let i = 0; i < dayCount; i++) {
      const leaveDate = new Date(startDate);
      leaveDate.setDate(leaveDate.getDate() + i);

      if (
        weekHoliday.includes(leaveDate.getDay()) ||
        yearlyHolidayDate.includes(leaveDate.getTime())
      ) {
        numberOfHoliday++;
      }
    }

    const numOfLeave = dayCount - numberOfHoliday;
    return numOfLeave;
  };

  //  Total difference in days
  const dateDifferenceInDays = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const differenceInMilliseconds = endDate - startDate;

    const differenceInDays = differenceInMilliseconds / (24 * 60 * 60 * 1000);

    return differenceInDays;
  };

  const onSubmit = async(data) => {
    setSubmitting(true);
    if (data.type.toLowerCase() !== 'special leave') {
      data.type = data?.type.split(' ')[0];
    }

    const dayCount = dateDifferenceInDays(data?.startDate, data?.endDate) + 1;

    const numOfLeave = await totalNumberOfLeaves(dayCount, data?.startDate);
    if (
      (numOfLeave <= remainingSickLeave && data?.type?.toLowerCase() === 'sick') ||
      (numOfLeave <= remainingCasualLeave && data?.type?.toLowerCase() === 'casual') ||
      (numOfLeave <= remainingFamilyLeave && data?.type?.toLowerCase() === 'family') ||
      (numOfLeave <= remainingHolidayLeave && data?.type?.toLowerCase() === 'holiday') ||
      (numOfLeave <= remainingParenthoodLeave && data?.type?.toLowerCase() === 'parenthood') ||
      (numOfLeave <= remainingMaritalLeave && data?.type?.toLowerCase() === 'marital') ||
      (numOfLeave <= remainingBonusLeave && data?.type?.toLowerCase() === 'bonus') ||
      employeeData?.totalLeave <= 0 ||
      data?.type?.toLowerCase() === 'unpaid'
    ) {
      fetch(`${apiUrl}/leave/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${tokenValue}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          setMessage(data.msg || data.error);
          setSubmitting(false);
          if (data.msg === 'Leave added successfully') {
            reset();
          }
          onOpenModal();
        })
        .catch((error) => {
          setMessage(error);
          setSubmitting(false);
          onOpenModal();
        });
    } else {
      setSubmitting(false);
      setMessage(
        `Sorry, we are unable to proceed your request as your maximum number ${data?.type} leaves has been exceeded.`
      );
      onOpenModal();
    }
  };

  //    check password event
  const startDateValue = watch('startDate');
  return (
    <main>
      <div className="container">
        {loading && <h6>Loading data....</h6>}
        {error && <h6>{error}</h6>}
        {!loading && (
          <>
            <div className="infoSummary">
              <div className="gridRow">
                <div className="card dash-widget shadow-sm">
                  <div className="card-body">
                    <div className="card-icon bg-primary">
                      <i className="fas fa-users" aria-hidden="true"></i>
                    </div>
                    <div className="card-right">
                      <h4 className="card-title">Consumed Leave</h4>
                      <p className="card-text">
                        {employeeData?.consumedLeave ? employeeData?.consumedLeave : '0'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card dash-widget shadow-sm">
                  <div className="card-body">
                    <div className="card-icon bg-primary">
                      <i className="fas fa-user-friends" aria-hidden="true"></i>
                    </div>
                    <div className="card-right">
                      <h4 className="card-title">Available Leave</h4>
                      <p className="card-text">
                        {employeeData?.availableLeave ? employeeData?.availableLeave : '0'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card dash-widget shadow-sm">
                  <div className="card-body">
                    <div className="card-icon bg-primary">
                      <i className="fas fa-calendar-alt" aria-hidden="true"></i>
                    </div>
                    <div className="card-right">
                      <h4 className="card-title">Total Leave</h4>
                      <p className="card-text">{employeeData?.totalLeave}</p>
                    </div>
                  </div>
                </div>
                <div className="card dash-widget shadow-sm">
                  <div className="card-body">
                    <div className="card-right">
                      <h4 className="card-title">Consumed Leave Summary</h4>
                      {leaveSummery?.casual !== 0 && (
                        <h4 className="card-title">Casual Consumed: {leaveSummery?.casual}</h4>
                      )}
                      {leaveSummery?.sick !== 0 && (
                        <h4 className="card-title">Sick Consumed: {leaveSummery?.sick}</h4>
                      )}
                      {leaveSummery?.family !== 0 && (
                        <h4 className="card-title">Family Consumed: {leaveSummery?.family}</h4>
                      )}
                      {leaveSummery?.special !== 0 && (
                        <h4 className="card-title">Special Consumed: {leaveSummery?.special}</h4>
                      )}
                      {leaveSummery?.unpaid !== 0 && (
                        <h4 className="card-title">Unpaid Consumed: {leaveSummery?.unpaid}</h4>
                      )}
                      {leaveSummery?.holiday !== 0 && (
                        <h4 className="card-title">Holiday Consumed: {leaveSummery?.holiday}</h4>
                      )}
                      {leaveSummery?.parenthood !== 0 && (
                        <h4 className="card-title">Parenthood Consumed: {leaveSummery?.parenthood}</h4>
                      )}
                      {leaveSummery?.marital !== 0 && (
                        <h4 className="card-title">Marital Consumed: {leaveSummery?.marital}</h4>
                      )}
                      {leaveSummery?.bonus !== 0 && (
                        <h4 className="card-title">Bonus Consumed: {leaveSummery?.bonus}</h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h1>Apply For Leave</h1>
            <BackButton />{' '}
            <Link className="yearly-holiday-user btn backBtn" to={'/add-holiday'}>
              Yearly Holiday
            </Link>
            <Link to={'/apply-holiday-leave'} className='yearly-holiday-user btn backBtn'>
            Request to include holiday leave
            </Link>
            <div className="book__form">
              <form method="post" className="form leaveForm" onSubmit={handleSubmit(onSubmit)}>
                <input
                  type={'hidden'}
                  defaultValue={employeeData._id}
                  {...register('employeeId')}
                />
                <input
                  type={'hidden'}
                  defaultValue={employeeData.supervisorId}
                  {...register('supervisorId')}
                />
                <input
                  type={'hidden'}
                  defaultValue={employeeData.email}
                  {...register('employeeEmail')}
                />
                <input
                  type={'hidden'}
                  defaultValue={'pending'}
                  {...register('adminApprovalStatus')}
                />
                <TextInput
                  label="Date Form"
                  name="startDate"
                  id="startDate"
                  type="Date"
                  register={register}
                  rules={{
                    required: '*field is required',
                  }}
                  errors={errors}
                />
                <div className="form-item">
                  <label htmlFor="endDate"> Date To </label>
                  <input
                    id="endDate"
                    className="field_sm"
                    type="date"
                    {...register('endDate', {
                      required: 'end date is required',
                      validate: (value) =>
                        value >= startDateValue || `*The end date can't be small`,
                    })}
                  />
                  {errors.endDate && <span className="error">{errors.endDate.message}</span>}
                </div>
                <TextAreaInput label="Reason" name="reason" id="reason" register={register} />
                <SelectInput
                  label="Leave Type"
                  name="type"
                  id="leaveType"
                  options={[
                    {
                      name: `Casual ${
                        remainingCasualLeave
                          ? '(' + remainingCasualLeave + ' remaining)'
                          : '(0 remaining leave)'
                      }`,
                      id: 'casual',
                    },
                    {
                      name: `Sick ${
                        remainingSickLeave
                          ? '(' + remainingSickLeave + ' remaining)'
                          : '(0 remaining leave)'
                      }`,
                      id: 'sick',
                    },
                    {
                      name: `Family ${
                        remainingFamilyLeave
                          ? '(' + remainingFamilyLeave + ' remaining)'
                          : '(0 remaining leave)'
                      }`,
                      id: 'family',
                    },
                    {
                      name: `Holiday ${
                        remainingHolidayLeave
                          ? '(' + remainingHolidayLeave + ' remaining)'
                          : '(0 remaining leave)'
                      }`,
                      id: 'holiday',
                    },
                    {
                      name: `Parenthood ${
                        remainingParenthoodLeave
                          ? '(' + remainingParenthoodLeave + ' remaining)'
                          : '(0 remaining leave)'
                      }`,
                      id: 'parenthood',
                    },
                    {
                      name: `Marital ${
                        remainingMaritalLeave
                          ? '(' + remainingMaritalLeave + ' remaining)'
                          : '(0 remaining leave)'
                      }`,
                      id: 'marital',
                    },
                    {
                      name: `Bonus ${
                        remainingBonusLeave
                          ? '(' + remainingBonusLeave + ' remaining)'
                          : '(0 remaining leave)'
                      }`,
                      id: 'marital',
                    },
                    {
                      name: 'Unpaid Leave',
                      id: 'Unpaid',
                    },
                  ]}
                  register={register}
                />
                <div className="form-item">
                  <input className="btn btn-primary" type="submit" name="submit" value="Apply" disabled={submitting} />
                </div>
              </form>
              <Modal
                open={open}
                onClose={onCloseModal}
                center
                classNames={{
                  modal: 'customModal',
                }}
                animationDuration={800}
              >
                <p>{message}</p>
              </Modal>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default LeaveApplication;
